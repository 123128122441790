import Big from 'big.js';
import filter from 'lodash/filter';
import { fromJS, OrderedMap } from 'immutable';
import { toRound } from '../core';

import { TRANSACTION_STATES, EMPTY_VALUES } from '../../constants/Core';
import { getWalletPrecision, getCurrencyFromType } from './common';
import COLORS from '../../components/common/helpers/colors';

export const normalizeTransactionHistory = history => {
  const depositHistory =
    history[0] && Array.isArray(history[0])
      ? history[0].map(deposit => normalizeSingleDeposit(deposit))
      : [];

  const withdrawHistory =
    history[1] && Array.isArray(history[1])
      ? history[1].map(withdraw => normalizeSingleWithdrawal(withdraw))
      : [];

  let mergedHistory = depositHistory.concat(withdrawHistory);

  if (history.length > 2) {
    const transferHistory =
      history[2] && Array.isArray(history[2])
        ? history[2].map(transfer => normalizeSingleAssetTransfer(transfer))
        : [];
    mergedHistory = mergedHistory.concat(transferHistory);
  }

  return mergedHistory.sort(
    (current, next) => new Date(next.date) - new Date(current.date)
  );
};

export const normalizeSingleDeposit = deposit => ({
  id: `deposit${deposit.id}`,
  referenceId: deposit.id,
  txid: deposit.txid,
  address: deposit.fund_uid,
  remarks: deposit.fund_extra,
  amount: deposit.amount,
  fee:
    !!deposit.fee && toRound(deposit.fee, getWalletPrecision(deposit.currency)),
  symbol: deposit.currency,
  addressUrl: deposit.blockchain_url || deposit.address_url,
  type: 'deposit',
  color: COLORS.green,
  volume:
    !!deposit.amount &&
    toRound(deposit.amount, getWalletPrecision(deposit.currency)),
  status: deposit.state || deposit.aasm_state,
  date: new Date(deposit.created_at),
  network: deposit.blockchain_network || deposit.network
});

export const transferStatusMapper = (status, t) => {
  switch (status) {
    case TRANSACTION_STATES.DONE:
      return t('common.statuses.done');
    case TRANSACTION_STATES.PROCESSING:
      return t('common.statuses.processingTransfer');
    case TRANSACTION_STATES.FAILED:
      return t('common.statuses.transferFailed');
    default:
      return status;
  }
};

export const depositStatusMapper = (status, t) => {
  switch (status) {
    case TRANSACTION_STATES.ACCEPTED:
      return t('common.statuses.done');
    case TRANSACTION_STATES.SUBMITTED:
    case TRANSACTION_STATES.SUBMITTING:
    case TRANSACTION_STATES.CHECKED:
      return t('common.statuses.processingDeposit');
    case TRANSACTION_STATES.CANCELLED:
    case TRANSACTION_STATES.REJECTED:
    case TRANSACTION_STATES.WARNING:
      return t('common.statuses.depositFailed');
    default:
      return status;
  }
};

const normalizeSingleWithdrawal = withdraw => ({
  referenceId: withdraw.id,
  txid: withdraw.txid,
  address: withdraw.fund_uid,
  remarks: withdraw.fund_extra,
  fee:
    !!withdraw.fee &&
    toRound(withdraw.fee, getWalletPrecision(withdraw.currency)),
  addressUrl: withdraw.blockchain_url || withdraw.address_url,
  amount: withdraw.amount,
  id: `withdraw${withdraw.id}`,
  symbol: withdraw.currency,
  type: 'withdraw',
  color: COLORS.red,
  volume:
    !!withdraw.amount &&
    toRound(withdraw.amount, getWalletPrecision(withdraw.currency)),
  status: withdraw.aasm_state,
  displayStatus: withdraw.display_status,
  network: withdraw.blockchain_network || withdraw.network,
  date: new Date(withdraw.created_at),
  bankAccountNumber: withdraw.bank_acc_number,
  ifscCode: withdraw.ifsc_code,
  bankRefNum: withdraw.bank_ref_num,
  failureReason: withdraw.failure_reason,
  savedAddressDetail: withdraw.address_book
});

const normalizeAssetTransferStatus = status => {
  switch (status) {
    case 'SUCCESS':
      return TRANSACTION_STATES.DONE;
    case 'FAILURE':
      return TRANSACTION_STATES.FAILED;
    case 'PENDING':
      return TRANSACTION_STATES.PROCESSING;
    default:
      return status;
  }
};

export const normalizeSingleAssetTransfer = transfers => {
  const status = normalizeAssetTransferStatus(transfers.status);
  let direction;

  if (transfers.to === 'binance') direction = 'to Binance';

  if (transfers.from === 'binance') direction = 'from Binance';

  return {
    referenceId: transfers.orderId,
    volume:
      EMPTY_VALUES.indexOf(transfers.amount) === -1 &&
      toRound(transfers.amount, getWalletPrecision(transfers.currency)),
    status,
    id: `transfer${transfers.orderId}`,
    symbol: transfers.currency,
    type: 'transfer',
    color: COLORS.background.warning,
    date: new Date(transfers.createdAt),
    direction,
    from: transfers.from,
    to: transfers.to,
    fee: transfers.fee,
    message: transfers?.errorMessage
  };
};

export const normalizeLiveWithdrawal = (withdraw, history) => {
  const withdrawHistory = normalizeSingleWithdrawal(withdraw);
  return history
    .concat(withdrawHistory)
    .sort((current, next) => new Date(next.date) - new Date(current.date));
};

export const normalizeLiveDeposit = (deposit, history) => {
  const depositHistory = normalizeSingleDeposit(deposit);
  return history
    .concat(depositHistory)
    .sort((current, next) => new Date(next.date) - new Date(current.date));
};

export const normalizeFunds = funds => {
  // INFO:
  // Due to some internal implementation of fromJS,
  // the sequence in which this <key,value> paired array is
  // changing the sequence of keys when storing as immutable record.
  // To fix this we have used OrderedMap.

  // also this now contains portfolio data as well

  const OrderedNormalizedFund = new OrderedMap(
    funds.map(fund => {
      const currency = getCurrencyFromType(fund.asset);
      // Check if the currency exists and is not hidden.
      if (currency && !currency.isHidden) {
        const balance = new Big(fund.free);
        const inOrders = new Big(fund.locked);
        const total = balance.plus(inOrders);
        return [
          fund.asset,
          fromJS({
            symbol: fund.asset,
            currency: currency.name,
            balance: fund.free,
            inOrders: fund.locked,
            total: total.toFixed(),
            deposit: {},
            withdrawal: {},
            searchString: `${fund.asset.toLowerCase()} ${currency.name.toLowerCase()}`,
            reservedFee: fund.reservedFee
          })
        ];
      }
      return null;
    })
  );

  return OrderedNormalizedFund;
};

export const updateFundsStatus = (newData, funds) =>
  funds.map(fund => {
    if (fund.id === newData.id) {
      fund.status = newData.status;
    }
    return fund;
  });

export const updateFunds = (data, funds) => {
  let newFunds;
  if (funds.has(data.currency)) {
    const balance = new Big(data.balance);
    const inOrders = new Big(data.locked);
    const total = balance.plus(inOrders);
    newFunds = funds.mergeIn([data.currency], {
      balance,
      inOrders,
      total
    });
  } else {
    const currency = getCurrencyFromType(data.currency);
    // Check if the currency exists and is not hidden.
    if (currency && !currency.isHidden) {
      const balance = new Big(data.balance);
      const inOrders = new Big(data.locked);
      const total = balance.plus(inOrders);
      newFunds = funds.set(
        data.currency,
        fromJS({
          id: data.id,
          symbol: data.currency,
          currency: currency.name,
          balance: data.balance,
          inOrders: data.locked,
          total: total.toFixed(),
          deposit: {},
          withdrawal: {},
          searchString: `${data.currency.toLowerCase()} ${currency.name.toLowerCase()}`
        })
      );
    }
  }
  return new OrderedMap(newFunds);
};

export const normalizeAssetTransferCurrencies = ({ allowedCurrencies }) => {
  return filter(allowedCurrencies, currencyItem => {
    const currency = getCurrencyFromType(currencyItem.code);
    return currency && !currency.isHidden;
  });
};

export const normalizeGatewayDeposit = deposit => {
  const {
    created_at,
    amount,
    fee,
    currency,
    id,
    state,
    txid,
    extraInfo: { mode, expiry, expiresAt, requestedUpi, uiType }
  } = deposit || {};
  return {
    createdAt: new Date(created_at),
    expiresAt: new Date(expiresAt),
    expiry,
    requestedUPI: requestedUpi,
    amount: parseFloat(amount),
    currency,
    id,
    state,
    txid,
    mode,
    uiType,
    fee: parseFloat(fee)
  };
};

export const normalizeCurrencyNetworks = (
  networkData = [],
  oldCurrencyNetworksData = fromJS({})
) => {
  let currencyNetworkMapping = oldCurrencyNetworksData; // initialize with old mapping
  if (networkData?.length) {
    let currencyNetworks = {};
    //user has fetched all/some the currencies, hence array has come in response
    networkData?.length &&
      networkData.forEach(item => {
        currencyNetworks[item['currency']] = item['networkList'];
      });

    currencyNetworkMapping = oldCurrencyNetworksData.merge(currencyNetworks);
  } else if (networkData?.currency) {
    //user has fetched a specific currency, so object has come in response
    let currencyNetwork = {};
    currencyNetwork[networkData['currency']] = networkData['networkList'];
    currencyNetworkMapping = oldCurrencyNetworksData.merge(currencyNetwork);
  }

  return currencyNetworkMapping;
};

export const normalizePortfolio = portfolio => {
  const OrderedNormalizedPortfolio = new Map(
    portfolio.tokens.map(token => {
      const currency = getCurrencyFromType(token.currency);
      // Check if the currency exists and is not hidden.
      if (currency) {
        return [
          token.currency,
          fromJS({
            ...token
            /*
            
            These keys come in this DTO : 

             avgBuyPrice : "22.04"
             currency : "xrp"
             dayAvgBuyPrice : "51"
             quoteCurrency :"inr"

             */
          })
        ];
      }
      return null;
    })
  );

  return OrderedNormalizedPortfolio;
};
