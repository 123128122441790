import { fromJS } from 'immutable';
import * as types from '../constants/ActionTypes';

const initialState = fromJS({
  config: {
    isFetching: false,
    isFetchingError: false,
    error: null,
    data: null
  },
  nominees: {
    isFetching: false,
    isFetchingError: false,
    error: null,
    data: null
  },
  nominee: {
    isFetching: false,
    isFetchingError: false,
    error: null,
    data: null
  },
  addNominee: {
    isFetching: false,
    isFetchingError: false,
    error: null,
    data: null
  },
  verifyAddNominee: {
    isFetching: false,
    isFetchingError: false,
    error: null,
    data: null
  },
  removeNominee: {
    isFetching: false,
    isFetchingError: false,
    error: null,
    data: null
  },
  verifyRemoveNominee: {
    isFetching: false,
    isFetchingError: false,
    error: null,
    data: null
  }
});

export default function(state = initialState, action) {
  switch (action.type) {
    case types.FETCH_NOMINEE_CONFIG_INIT:
      return state.mergeIn(['config'], {
        isFetching: true,
        isFetchingError: false
      });
    case types.FETCH_NOMINEE_CONFIG_SUCCESS:
      return state.mergeIn(['config'], {
        isFetching: false,
        isFetchingError: false,
        data: action.data
      });
    case types.FETCH_NOMINEE_CONFIG_ERROR:
      return state.mergeIn(['config'], {
        isFetching: false,
        isFetchingError: true,
        error: action.error
      });

    case types.FETCH_NOMINEES_INIT:
      return state.mergeIn(['nominees'], {
        isFetching: true,
        isFetchingError: false,
        data: null
      });
    case types.FETCH_NOMINEES_SUCCESS:
      return state.mergeIn(['nominees'], {
        isFetching: false,
        isFetchingError: false,
        data: action.data
      });
    case types.FETCH_NOMINEES_ERROR:
      return state.mergeIn(['nominees'], {
        isFetching: false,
        isFetchingError: true,
        error: action.error
      });

    case types.FETCH_NOMINEE_INIT:
      return state.mergeIn(['nominee'], {
        isFetching: true,
        isFetchingError: false
      });
    case types.FETCH_NOMINEE_SUCCESS:
      return state.mergeIn(['nominee'], {
        isFetching: false,
        isFetchingError: false,
        data: action.data
      });
    case types.FETCH_NOMINEE_ERROR:
      return state.mergeIn(['nominee'], {
        isFetching: false,
        isFetchingError: true,
        error: action.error
      });

    case types.FETCH_ADD_NOMINEE_INIT:
      return state.mergeIn(['addNominee'], {
        isFetching: true,
        isFetchingError: false
      });
    case types.FETCH_ADD_NOMINEE_SUCCESS:
      return state.mergeIn(['addNominee'], {
        isFetching: false,
        isFetchingError: false,
        data: action.data
      });
    case types.FETCH_ADD_NOMINEE_ERROR:
      return state.mergeIn(['addNominee'], {
        isFetching: false,
        isFetchingError: true,
        error: action.error
      });

    case types.FETCH_VERIFY_ADD_NOMINEE_INIT:
      return state.mergeIn(['verifyAddNominee'], {
        isFetching: true,
        isFetchingError: false
      });
    case types.FETCH_VERIFY_ADD_NOMINEE_SUCCESS:
      return state.mergeIn(['verifyAddNominee'], {
        isFetching: false,
        isFetchingError: false,
        data: action.data
      });
    case types.FETCH_VERIFY_ADD_NOMINEE_ERROR:
      return state.mergeIn(['verifyAddNominee'], {
        isFetching: false,
        isFetchingError: true,
        error: action.error
      });

    case types.FETCH_REMOVE_NOMINEE_INIT:
      return state.mergeIn(['removeNominee'], {
        isFetching: true,
        isFetchingError: false
      });
    case types.FETCH_REMOVE_NOMINEE_SUCCESS:
      return state.mergeIn(['removeNominee'], {
        isFetching: false,
        isFetchingError: false,
        data: action.data
      });
    case types.FETCH_REMOVE_NOMINEE_ERROR:
      return state.mergeIn(['removeNominee'], {
        isFetching: false,
        isFetchingError: true,
        error: action.error
      });

    case types.FETCH_VERIFY_REMOVE_NOMINEE_INIT:
      return state.mergeIn(['verifyRemoveNominee'], {
        isFetching: true,
        isFetchingError: false
      });
    case types.FETCH_VERIFY_REMOVE_NOMINEE_SUCCESS:
      return state.mergeIn(['verifyRemoveNominee'], {
        isFetching: false,
        isFetchingError: false,
        data: action.data
      });
    case types.FETCH_VERIFY_REMOVE_NOMINEE_ERROR:
      return state.mergeIn(['verifyRemoveNominee'], {
        isFetching: false,
        isFetchingError: true,
        error: action.error
      });

    default:
      return state;
  }
}
