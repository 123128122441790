import { createGlobalStyle } from 'styled-components';

const GlobalStyles = createGlobalStyle`
  body, html, #root {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    background-color: var(--color-background-default);
    overflow: hidden;
  }
  a {
    color: var(--color-text-link)
  }
  img {
    max-width: 100%;
    height: auto;
  }
  *, *:before, *:after {
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    margin: 0;
    padding: 0;
    font-family: 'IBM Plex Sans', sans-serif;
  }
  *:focus {
    outline: none;
  }
  input[type=number]::-webkit-inner-spin-button, 
  input[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none; 
    margin: 0; 
  }
  a {
    text-decoration: none !important;
  }
  .row {
    margin: 0;
    width: 100%;
  }
  p {
    margin: 0;
  }
  .navbar {
    margin-bottom: 0;
    border: none;
  }
  .navbar-nav>li>a {
    padding: 0;
  }
  .container {
    margin: 0 auto;
    max-width: 940px;
    padding: 0;
    min-height: 42px;
  }
  .container>.navbar-header {
    margin: 0;
  }
  @media only screen and (max-width: 990px) {
    .navbar-nav {
      margin: 0;
    }
    .navbar-brand {
      padding: 0;
    }
    
    .container>.navbar-collapse {
      margin: 0;
      width: 100%;
      padding-right: 0px; // unset the ugly padding in the mobile navbar
      padding-left: 0px;
    }
    
    .navbar-default .navbar-toggle, .navbar-default .navbar-toggle:hover, .navbar-default .navbar-toggle:active {
      background: transparent;
      border: none;
    }
    
    .navbar-default .navbar-toggle .icon-bar {
      background: var(--color-inner-header-background);
    }
  }
  
  .navbar-default {
    z-index: 10;
  }
  .navbar>.container .navbar-brand {
    margin-left: 0;
  }
`;

export default GlobalStyles;
