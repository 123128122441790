import { fromJS } from 'immutable';
import * as types from '../constants/ActionTypes';
import {
  normalizeExchangeCurrency,
  normalizeTradeHistory,
  normalizeLiveTradeHistory,
  normalizeLiveExchangeCurrency
} from '../utils/normalizer/exchange';
import { normalizeTickerQuoteCurrency } from '../utils/normalizer/common';

const initialState = fromJS({
  market: {
    isFetching: false,
    isFetchedOnce: false,
    isFetchingError: false
  },
  prices: {
    isFetching: false,
    isFetchedOnce: false,
    isFetchingError: false
  },
  tradeHistory: {
    isFetching: false,
    isFetchingError: false,
    isFetchedOnce: false
  },
  config: {
    isFetching: false,
    isFetchingError: false
  },
  hotMarkets: {
    isFetching: false,
    isFetchingError: false,
    data: null,
    error: null
  }
});

export default function(state = initialState, action) {
  let newTickerData;
  let quoteCurrencyList;
  let normalizedTradeHistoryData;
  switch (action.type) {
    case types.FETCH_TICKER_INIT:
      return state.mergeIn(['market'], {
        isFetching: true,
        isFetchingError: false
      });
    case types.FETCH_TICKER_SUCCESS:
      newTickerData = normalizeExchangeCurrency(action.data);
      quoteCurrencyList = normalizeTickerQuoteCurrency(action.data);
      return state.mergeIn(['market'], {
        isFetching: false,
        isFetchingError: false,
        isFetchedOnce: true,
        ticker: newTickerData,
        quoteCurrencyList
      });
    case types.FETCH_TICKER_ERROR:
      return state.mergeIn(['market'], {
        isFetching: false,
        isFetchingError: true,
        error: action.error
      });
    case types.FETCH_TICKER_LIVE:
      newTickerData = normalizeLiveExchangeCurrency(action.data);
      return state.mergeIn(['market', 'ticker'], {
        ...newTickerData
      });

    case types.FETCH_TRADE_HISTORY_INIT:
      return state.mergeIn(['tradeHistory', action.market], {
        isFetching: true,
        isFetchingError: false,
        isFetchedOnce: false,
        data: null,
        originalData: null
      });

    case types.FETCH_TRADE_HISTORY_SUCCESS:
      normalizedTradeHistoryData = normalizeTradeHistory(
        action.data,
        action.market
      );
      return state.mergeIn(['tradeHistory', action.market], {
        isFetching: false,
        isFetchingError: false,
        isFetchedOnce: true,
        data: normalizedTradeHistoryData
      });

    case types.FETCH_TRADE_HISTORY_LIVE: {
      const tradeHistory = state.getIn(['tradeHistory', action.market, 'data']);
      const trades = normalizeLiveTradeHistory(
        tradeHistory,
        action.data,
        action.market
      );
      const newState = state.mergeIn(['tradeHistory', action.market], {
        data: trades
      });
      return newState;
    }
    case types.FETCH_TRADE_HISTORY_ERROR:
      return state.mergeIn(['tradeHistory', action.market], {
        isFetching: false,
        isFetchingError: true,
        isFetchedOnce: false,
        message: action.data
      });

    case types.RESET_EXCHANGE_DATA:
      return state.merge({
        tradeHistory: {}
      });
    case types.FETCH_CURRENCY_PRICES_INIT:
      return state.mergeIn(['prices'], {
        isFetching: true,
        isFetchingError: false
      });
    case types.FETCH_CURRENCY_PRICES_SUCCESS:
      return state.mergeIn(['prices'], {
        isFetching: false,
        isFetchingError: false,
        isFetchedOnce: true,
        data: action.data
      });
    case types.FETCH_CURRENCY_PRICES_LIVE:
      return state.mergeDeepIn(['prices', 'data'], fromJS(action.data));
    case types.FETCH_CURRENCY_PRICES_ERROR:
      return state.mergeIn(['prices'], {
        isFetching: false,
        isFetchingError: true,
        message: action.message
      });

    case types.FETCH_HOT_MARKETS_INIT:
      return state.mergeIn(['hotMarkets'], {
        isFetching: true,
        isFetchingError: false
      });
    case types.FETCH_HOT_MARKETS_SUCCESS:
      return state.mergeIn(['hotMarkets'], {
        isFetching: false,
        isFetchingError: false,
        data: action.data
      });
    case types.FETCH_HOT_MARKETS_ERROR:
      return state.mergeIn(['hotMarkets'], {
        isFetching: false,
        isFetchingError: true,
        error: action.message
      });

    default:
      return state;
  }
}
