import { fromJS } from 'immutable';
import * as types from '../constants/ActionTypes';
import {
  normalizeOrderBook,
  normalizeOrderHistory,
  deleteFromOrder,
  normalizeLiveOrderHistory,
  normlizedSingleOrderObject,
  normalizeLiveOrderBook
} from '../utils/normalizer/orders';
import { MY_ORDER_MODE, ORDER_HISTORY_LIMIT } from '../constants/Core';

const initialState = fromJS({
  order: {
    isDeleting: false,
    placedOrderId: null,
    buy: {
      isSending: false,
      isSendingError: false,
      data: {}
    },
    sell: {
      isSending: false,
      isSendingError: false,
      data: {}
    },
    book: {},
    open: {
      isFetching: false,
      isFetchingError: false,
      hasMore: false,
      data: []
    },
    completed: {
      isFetching: false,
      isFetchingError: false,
      hasMore: false,
      data: []
    }
  }
});

const resetState = initialState;

export default function(state = initialState, action) {
  let oldOrderData;
  let newOrderData;
  switch (action.type) {
    case types.PLACE_ORDER_INIT:
      return state.mergeIn(['order', action.side], {
        isSending: true,
        isSendingError: false,
        data: 'loading'
      });

    case types.PLACE_ORDER_SUCCESS:
      return state.mergeIn(['order', action.side], {
        isSending: false,
        isSendingError: false,
        data: 'success'
      });

    case types.SET_PLACED_ORDER_ID:
      return state.mergeIn(['order'], {
        placedOrderId: action.id
      });

    case types.RESET_PLACED_ORDER_ID:
      return state.mergeIn(['order'], {
        placedOrderId: null
      });

    case types.PLACE_ORDER_ERROR:
      return state.mergeIn(['order', action.side], {
        isSending: false,
        isSendingError: true,
        data: 'error'
      });

    case types.FETCH_ORDER_BOOK_INIT:
      return state.mergeIn(['order', 'book', action.selectedMarket], {
        isFetching: true,
        isFetchingError: false
      });
    case types.FETCH_ORDER_BOOK_LIVE:
      return state.mergeIn(['order', 'book', action.selectedMarket], {
        isFetching: false,
        isFetchingError: false,
        data: normalizeLiveOrderBook(action.data, action.selectedMarket)
      });
    case types.FETCH_ORDER_BOOK_SUCCESS:
      return state.mergeIn(['order', 'book', action.selectedMarket], {
        isFetching: false,
        isFetchingError: false,
        data: normalizeOrderBook(action.data, action.selectedMarket)
      });
    case types.FETCH_ORDER_BOOK_ERROR:
      return state.mergeIn(['order', 'book', action.selectedMarket], {
        isFetching: false,
        isFetchingError: true,
        message: action.message
      });

    case types.FETCH_ORDERS_INIT:
      return state.mergeIn(['order', action.orderMode], {
        isFetching: true,
        isFetchingError: false
      });

    case types.FETCH_ORDERS_SUCCESS:
      return state.mergeIn(['order', action.orderMode], {
        isFetching: false,
        isFetchingError: false,
        data: normalizeOrderHistory(action.data),
        hasMore: action.data.length === ORDER_HISTORY_LIMIT
      });

    case types.FETCH_ORDERS_ERROR:
      return state.mergeIn(['order', action.orderMode], {
        isFetching: false,
        isFetchingError: true,
        message: action.message,
        hasMore: false
      });

    case types.FETCH_ORDER_DETAIL_INIT: {
      const orderDetail = {
        isFetchingOrderDetails: true,
        isFetchingOrderDetailsError: false,
        errorMessage: null,
        id: action.body.orderId
      };

      const orderIndex = state
        .getIn(['order', MY_ORDER_MODE.COMPLETED, 'data'])
        .findIndex(order => order.get('id') === action.body.orderId);

      if (orderIndex > -1) {
        return state.mergeIn(
          ['order', MY_ORDER_MODE.COMPLETED, 'data', orderIndex],
          orderDetail
        );
      } else {
        return state.updateIn(
          ['order', MY_ORDER_MODE.COMPLETED, 'data'],
          data => {
            return data.push(fromJS(orderDetail));
          }
        );
      }
    }
    case types.FETCH_ORDER_DETAIL_SUCCESS: {
      const orderIndex = state
        .getIn(['order', MY_ORDER_MODE.COMPLETED, 'data'])
        .findIndex(order => order.get('id') === action.body.orderId);

      const orderDetail = {
        isFetchingOrderDetails: false,
        isFetchingOrderDetailsError: false,
        errorMessage: null,
        id: action.body.orderId,
        ...normlizedSingleOrderObject(action.data)
      };

      if (orderIndex > -1) {
        return state.mergeIn(
          ['order', MY_ORDER_MODE.COMPLETED, 'data', orderIndex],
          orderDetail
        );
      } else {
        return state.updateIn(
          ['order', MY_ORDER_MODE.COMPLETED, 'data'],
          data => {
            return data.push(fromJS(orderDetail));
          }
        );
      }
    }

    case types.FETCH_ORDER_DETAIL_ERROR: {
      const orderIndex = state
        .getIn(['order', MY_ORDER_MODE.COMPLETED, 'data'])
        .findIndex(order => order.get('id') === action.body.orderId);

      const orderDetail = {
        isFetchingOrderDetails: false,
        isFetchingOrderDetailsError: true,
        errorMessage: action.message,
        id: action.body.orderId
      };

      if (orderIndex > -1) {
        return state.mergeIn(
          ['order', MY_ORDER_MODE.COMPLETED, 'data', orderIndex],
          orderDetail
        );
      } else {
        return state.updateIn(
          ['order', MY_ORDER_MODE.COMPLETED, 'data'],
          data => {
            return data.push(fromJS(orderDetail));
          }
        );
      }
    }

    case types.FETCH_ORDERS_LIVE: {
      const currentOrders = state.getIn(['order', action.orderMode, 'data']);
      return state.mergeIn(['order', action.orderMode], {
        data: normalizeLiveOrderHistory(
          action.orderMode,
          action.data,
          currentOrders
        )
      });
    }

    case types.FETCH_ORDERS_NEXT: {
      oldOrderData = state.getIn(['order', action.orderMode, 'data']);
      newOrderData = normalizeOrderHistory(action.data);
      return state.mergeIn(['order', action.orderMode], {
        isFetching: false,
        isFetchingError: false,
        data: oldOrderData.concat(fromJS(newOrderData)),
        hasMore: action.data.length === ORDER_HISTORY_LIMIT
      });
    }

    case types.SLICE_COMPLETED_ORDERS: {
      return state.updateIn(
        ['order', MY_ORDER_MODE.COMPLETED, 'data'],
        data => {
          if (data && data.size > 0) {
            return data.slice(0, ORDER_HISTORY_LIMIT);
          }
          return data;
        }
      );
    }

    case types.CANCEL_ORDER_INIT:
      return state.mergeIn(['order', MY_ORDER_MODE.OPEN], {
        isDeleting: true,
        isDeletingError: false
      });

    case types.CANCEL_ORDER_SUCCESS: {
      oldOrderData = state.getIn(['order', MY_ORDER_MODE.OPEN, 'data']);
      const filteredOrders = deleteFromOrder(oldOrderData, action.id);
      return state.mergeIn(['order', MY_ORDER_MODE.OPEN], {
        isDeleting: false,
        isDeletingError: false,
        data: filteredOrders
      });
    }
    case types.CANCEL_ORDER_ERROR:
      return state.mergeIn(['order', MY_ORDER_MODE.OPEN], {
        isDeleting: false,
        isDeletingError: true,
        errorMessage: action.message
      });

    case types.CANCEL_ALL_ORDER_INIT:
      return state.mergeIn(['order'], {
        isDeleting: true
      });

    case types.CANCEL_ALL_ORDER_SUCCESS:
      return state.mergeIn(['order'], {
        isDeleting: false
      });

    case types.CANCEL_ALL_ORDER_ERROR:
      return state.mergeIn(['order'], {
        isDeleting: false
      });

    case types.RESET_ORDER_DATA:
      state = resetState;
      return state;
    default:
      return state;
  }
}
