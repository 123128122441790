import { combineReducers } from 'redux';
import Common from './common';
import Orders from './orders';
import Transfers from './transfers';
import Profile from './profile';
import Exchange from './exchange';
import Verification from './verification';
import PasswordReset from './passwordReset';
import Notification from './notification';
import TwoFA from './twoFA';
import Settings from './settings';
import P2P from './p2p';
import Onboarding from './onboarding';
import OTC from './otc';
import SubAccounts from './subaccounts';
import Deposits from './deposits';
import Gifts from './gifts';
import Nominee from './nominee';

const rootReducer = combineReducers({
  Common,
  Orders,
  Transfers,
  Profile,
  Exchange,
  Verification,
  PasswordReset,
  Notification,
  TwoFA,
  Settings,
  P2P,
  Onboarding,
  OTC,
  SubAccounts,
  Deposits,
  Gifts,
  Nominee
});

export default rootReducer;
