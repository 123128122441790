import Big from 'big.js';
import { addMinutes, getUnixTime } from 'date-fns';
import { getFromLocalStorage } from '../localStorageHelpers';
import { KYC_FORM_VISIBILITY_STATUSES } from '../../constants/Verification';
import { DEPOSIT_BONUS_STATUS_MAPPER } from '../../constants/StatusCodes';
import {
  ZERO_VALUES,
  KYC_VERIFICATION_STATES,
  EMPTY_VALUES,
  KYC_VERIFICATION_USER_STATE
} from '../../constants/Core';
import { getBasePrecision } from './common';
import { toFixed } from '../core';

export const normalizeUserProfile = data => {
  const {
    verification_stages,
    connectedThirdparties,
    preferredQuoteCurrency,
    username,
    name: first_name,
    middle_name,
    last_name,
    sn,
    email,
    country_code,
    country,
    phone_number,
    activated,
    userSettings,
    memberType,
    subAccountEnabled
  } = data;

  const verification_aggregates = normalizeVerificationAggregates(
    verification_stages
  );
  const name =
    first_name || middle_name || last_name
      ? [first_name, middle_name, last_name].join(' ')
      : null;

  const mobile =
    EMPTY_VALUES.indexOf(country_code) === -1 &&
    EMPTY_VALUES.indexOf(phone_number) === -1
      ? `+${data.country_code}${data.phone_number}`
      : undefined;
  return {
    serialNumber: sn,
    name,
    email,
    mobile,
    accessKey: getFromLocalStorage('accessKey'),
    secretKey: getFromLocalStorage('secretKey'),
    activated,
    userSettings,
    memberType,
    subAccountEnabled,
    username, // Since KYC is the last stage required to be verified
    preferredQuoteCurrency,
    connectedThirdparties,
    country,
    ...verification_aggregates
  };
};

export const normalizeVerificationAggregates = stages => {
  let isMandatoryVerified = true;
  let isCompletelyVerified = true;
  let hasSubmittedDocuments = false;
  let isKYCRejected = false;
  let isSelfDeclarationDone = false;
  let isLimitedAccess = false;

  stages.forEach(stage => {
    let isVerified = stage.isVerified;
    if (
      stage.type === 'kyc' &&
      stage.status === KYC_VERIFICATION_STATES.LIMITED_ACCESS
    ) {
      isLimitedAccess = true;
      isVerified = true;
    }
    if (stage.isMandatory)
      isMandatoryVerified = isVerified && isMandatoryVerified;

    isCompletelyVerified = isVerified && isCompletelyVerified;
    if (stage.type === 'kyc') {
      isSelfDeclarationDone = stage.isSelfDeclarationDone;
      isKYCRejected = stage.status === KYC_VERIFICATION_STATES.REJECTED;
      hasSubmittedDocuments = [
        KYC_VERIFICATION_STATES.AUTO_VERIFYING,
        KYC_VERIFICATION_STATES.MANUALLY_VERIFYING,
        KYC_VERIFICATION_STATES.VERIFYING
      ].includes(stage.status);
    }
  });

  let currentKYCStatus;
  if (isCompletelyVerified) {
    currentKYCStatus = KYC_VERIFICATION_USER_STATE.DONE;
  } else if (isKYCRejected) {
    currentKYCStatus = KYC_VERIFICATION_USER_STATE.REJECTED;
  } else if (hasSubmittedDocuments) {
    currentKYCStatus = KYC_VERIFICATION_USER_STATE.IN_REVIEW;
  } else {
    currentKYCStatus = KYC_VERIFICATION_USER_STATE.NOT_SUBMITTED;
  }

  return {
    isCompletelyVerified,
    isMandatoryVerified,
    hasSubmittedDocuments,
    currentKYCStatus,
    isSelfDeclarationDone,
    isLimitedAccess
  };
};

export const normalizeReferralsCount = data => ({
  data,
  emoji: data === 0 ? '🙁' : '🌟',
  message: data > 1 ? 'friends' : 'friend'
});

export const normalizeVerificationStages = verificationStages => {
  const mappedStages = [];
  verificationStages.forEach(stage => {
    let status = stage.status;
    let isVerified = stage.isVerified;
    let isLimitedAccess = false;
    if (
      stage?.type === 'kyc' &&
      status === KYC_VERIFICATION_STATES.LIMITED_ACCESS
    ) {
      status = 'verified';
      isVerified = true;
      isLimitedAccess = true;
    }
    mappedStages.push({
      name: stage.type,
      isVerified,
      isMandatory: stage.isMandatory,
      isVerifying: KYC_FORM_VISIBILITY_STATUSES.indexOf(status) === -1,
      source: stage.source,
      status,
      rejection_reason: stage.rejection_reason,
      verification_url: stage.verification_url,
      isLimitedAccess
    });
  });
  return mappedStages;
};

export const normalizeDepositBonusInfoData = depositBonusInfo => {
  const startDate = new Date(depositBonusInfo.startDate);
  const endDate = new Date(depositBonusInfo.endDate);
  const maturityEndDate = addMinutes(
    new Date(depositBonusInfo.endDate),
    depositBonusInfo.bonusMaturityMinutes || 0
  );
  const isActive = startDate < Date.now();
  const supportedCurrencies = {};
  if (depositBonusInfo.supportedCurrencies) {
    Object.keys(depositBonusInfo.supportedCurrencies).forEach(currency => {
      const currencyStartDate = new Date(
        depositBonusInfo.supportedCurrencies[currency].startDate
      );
      supportedCurrencies[currency] = {
        startDate: currencyStartDate,
        isActive: currencyStartDate && currencyStartDate < Date.now()
      };
    });
  }
  return {
    ...depositBonusInfo,
    startDate,
    endDate,
    maturityEndDate,
    isActive,
    supportedCurrencies
  };
};

export const normalizeCurrentDepositBonusData = currentDepositBonusData => {
  const data = {};
  if (currentDepositBonusData.optedIn) {
    if (
      currentDepositBonusData.currencies &&
      Object.keys(currentDepositBonusData.currencies).length > 0
    ) {
      data.hasDeposited = true;
    } else {
      data.hasDeposited = false;
    }
  } else {
    data.hasDeposited = false;
    data.optOutDate =
      currentDepositBonusData.optOutDate &&
      new Date(currentDepositBonusData.optOutDate);
  }
  return {
    ...currentDepositBonusData,
    ...data
  };
};

export const normalizeDepositBonusHistoryData = historyData => {
  const history =
    historyData &&
    historyData.history &&
    historyData.history.map(item => {
      const historyItem = {};
      historyItem.depositedAt = new Date(item.depositedAt);
      historyItem.creditOn = new Date(item.creditOn);
      historyItem.status = DEPOSIT_BONUS_STATUS_MAPPER[item.status];
      historyItem.bonusAmount = item.bonusAmount;
      historyItem.depositAmount = item.depositAmount;
      return {
        ...item,
        ...historyItem
      };
    });
  return {
    ...historyData,
    history
  };
};

export const normalizeNotificationPrefencences = data =>
  data && data.notificationPreferences;

export const normalizeCommissionHistoryData = data =>
  data &&
  data.map(item => {
    const createdAt = new Date(item.createdAt);
    const amount = new Big(item.amount);
    return {
      ...item,
      createdAt,
      amount: amount.toFixed()
    };
  });

export const normalizeReferredUsersData = data =>
  data &&
  data.map(item => {
    const redeemedAt = new Date(item.lastUpdated);
    return {
      id: item.id,
      redeemedAt,
      redeemerEmail: item.redeemerEmail
    };
  });

export const normalizeActivityLogs = data =>
  data &&
  data.map((item, index) => {
    const at = new Date(item.at);
    return {
      ...item,
      at,
      id: getUnixTime(at) + index
    };
  });

export const normalizeWRXBreakdown = data => {
  let initial = {};
  let fee = {};
  let vesting = {};
  let trading = {};
  const showWRXUnlockProgram = ZERO_VALUES.indexOf(data.totalReserved) === -1;
  if (data.initial) {
    initial = {
      total: data.initial.total,
      unlocked: data.initial.unlocked
    };
  }

  if (data.fee) {
    fee = {
      total: data.fee.total,
      unlocked: data.fee.unlocked,
      used: data.fee.used
    };
  }

  if (data.vesting) {
    vesting = {
      total: data.vesting.total,
      unlocked: data.vesting.unlocked
    };
  }

  if (data.trading) {
    trading = {
      total: data.trading.total,
      unlocked: data.trading.unlocked
    };
  }

  return {
    showWRXUnlockProgram,
    totalReserved: new Big(data.totalReserved),
    totalUnlocked: new Big(data.totalUnlocked),
    initial,
    fee,
    vesting,
    trading
  };
};

export const normalizeWRXUnlockSchedule = data => {
  const programStartDate = data.programStartDate;
  const programEndDate = data.programEndDate;
  const slots =
    data.slots &&
    data.slots.map(slot => {
      let tradeUnlock = {};
      let vestingUnlock = {};
      if (slot.tradeUnlock) {
        tradeUnlock = {
          allowed: new Big(slot.tradeUnlock.allowed),
          unlocked: new Big(slot.tradeUnlock.unlocked)
        };
      }
      if (slot.vestingUnlock) {
        vestingUnlock = {
          allowed: new Big(slot.vestingUnlock.allowed),
          unlocked: new Big(slot.vestingUnlock.unlocked)
        };
      }
      return {
        name: slot.name,
        startDate: slot.startDate,
        endDate: slot.endDate,
        tradeUnlock,
        vestingUnlock
      };
    });
  return {
    programStartDate,
    programEndDate,
    slots
  };
};

export const normalizeWRXUnlockHistory = data => {
  return (
    data &&
    data.map(item => ({
      ...item,
      initialAmount: new Big(item.initialAmount),
      amount: new Big(item.amount)
    }))
  );
};

export const normalizePreferences = (category, data) => {
  return data[category];
};

export const normalizeFavouriteMarkets = data => {
  const markets = {};
  data &&
    data.forEach(item => {
      if (markets[item.marketType]) {
        markets[item.marketType].push(item.market);
      } else {
        markets[item.marketType] = [item.market];
      }
    });
  return markets;
};

export const normalizeCoupon = coupon => {
  coupon.status = coupon.status === 'CLAIMED' ? 'PENDING' : coupon.status;
  const basePrecision = getBasePrecision(coupon.rewardCurrency);
  coupon.reward = toFixed(coupon.reward, basePrecision);
  return coupon;
};
export const normalizeCoupons = coupons => {
  return coupons && coupons.map(coupon => normalizeCoupon(coupon));
};
