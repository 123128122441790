import { getColorFromTheme } from '../../../utils/theme';

const COLORS = {
  // basic palette of our colors, most used, irrespective of the context
  primary: 'var(--color-primary)',
  primary30: 'var(--color-primary-30)',
  primaryGradient: 'var(--color-primary-gradient)',
  white: 'var(--color-white)',
  black: 'var(--color-black)',
  red: 'var(--color-red)',
  green: 'var(--color-green)',
  yellow: 'var(--color-yellow)',
  transparent: 'transparent',
  gray: 'var(--color-gray)',
  orange: 'var(--color-orange)',

  //important component
  importantComponent: {
    background: 'var(--color-important-component-bg)',
    text: 'var(--color-important-component-text)',
    border: 'var(--color-important-component-border)'
  },

  //for text, levels work as priorities. Lower means darker.
  text: {
    l0: 'var(--color-text-l0)', // heading
    l1: 'var(--color-text-l1)', //content
    l2: 'var(--color-text-l2)', //description
    l3: 'var(--color-text-l3)', //disabled-like
    white: 'var(--color-text-white)',
    link: 'var(--color-text-link)',
    linkHover: 'var(--color-text-link-hover)',
    highlight: 'var(--color-text-highlight)',
    primary: 'var(--color-text-primary)',
    orangeL10: 'var(--color-orange-text-l10)',
    warning: 'var(--color-warning-text)'
  },

  divider: 'var(--color-divider)',
  shadow: 'var(--color-shadow)',

  background: {
    // levels here work like z-index / elevation of card
    default: 'var(--color-background-default)',
    l0: 'var(--color-background-l0)', //card
    l1: 'var(--color-background-l1)', //above card
    l2: 'var(--color-background-l2)',
    activated: 'var(--color-background-activated)',
    selected: 'var(--color-background-selected)',
    modal: 'var(--color-background-modal)',
    hero: 'var(--color-background-hero)',
    toggle: 'var(--color-background-toggle)',
    hover: 'var(--color-background-hover)',

    input: 'var(--color-background-input)',
    inputOtp: 'var(--color-background-inputotp)',

    stepper: 'var(--color-background-stepper)',

    warning: 'var(--color-background-warning)',
    success: 'var(--color-background-success)',
    danger: 'var(--color-background-danger)',
    info: 'var(--color-background-info)',
    disabledInput: 'var(--color-disabled-input-bg)',
    iconBg: 'var(--color-icon-background)',
    orangeL1: 'var(--color-orange-bg-l1)'
  },
  border: {
    disabledInput: 'var(--color-disabled-input-border)',
    danger10: 'var(--color-background-danger-10)',
    danger40: 'var(--color-background-danger-40)',
    info: 'var(--color-background-info)',
    prevTab: 'var(--color-background-prev-tab)',
    currTab: 'var(--color-background-curr-tab)',
    nextTab: 'var(--color-background-next-tab)',
    overlay: 'var(--color-background-overlay)'
  },

  icon: {
    dangerIconBgColor: 'var(--color-background-danger-icon-bg)',
    dangerIconColor: 'var(--color-background-danger-icon-color)'
  },
  //these are defined as anonymous functions - will be called ON-DEMAND - when the theme changes
  tradingView: {
    background: () => getColorFromTheme('--color-tradingView-background'),
    grid: () => getColorFromTheme('--color-grid'),
    scale: () => getColorFromTheme('--color-text-l3'),
    text: () => getColorFromTheme('--color-text-l2'),
    green: () => getColorFromTheme('--color-green'),
    red: () => getColorFromTheme('--color-red'),
    brand: () => getColorFromTheme('--color-brand')
  },

  scrollbar: {
    background: 'var(--color-scrollbar-background)',
    thumb: 'var(--color-scrollbar-thumb)'
  },

  table: {
    rowHover: 'var(--color-table-row-hover)',
    rowGreenOverlay: 'var(--color-table-row-green-overlay)',
    rowRedOverlay: 'var(--color-table-row-red-overlay)',
    rowOdd: 'var(--color-table-row-odd)',
    rowEven: 'var(--color-table-row-even)'
  },

  binance: {
    /* binance theme colors */
    primary: 'var(--color-binance-primary)',
    black: 'var(--color-binance-black)'
  },

  moneycontrol: {
    /* moneycontrol theme colors */
    primary: 'var(--color-moneycontrol-primary)',
    blue: 'var(--color-moneycontrol-blue)',
    lightblue: 'var(--color-moneycontrol-lightblue)',
    lightgrey: 'var(--color-moneycontrol-lightgrey)',
    orange: 'var(--color-moneycontrol-orange)',
    green: 'var(--color-moneycontrol-green)',
    darkgreen: 'var(--color-moneycontrol-darkgreen)',
    grey: 'var(--color-moneycontrol-grey)',
    basemarket: 'var(--color-moneycontrol-basemarket)'
  },

  vendors: {
    facebook: 'var(--color-vendors-facebook)',
    whatsapp: 'var(--color-vendors-whatsapp)',
    telegram: 'var(--color-vendors-telegram)',
    twitter: 'var(--color-vendors-twitter)'
  },

  specialaccount: {
    /* special account / STFM account */
    primary: 'var(--color-specialaccount-primary)'
  },

  header: {
    border: 'var(--color-header-border)',
    signupBtn: 'var(--color-header-signup-btn)',
    signupBtnHover: 'var(--color-header-signup-btn-hover)',
    shadow: 'var(--color-header-shadow)'
  },
  outerHeader: {
    background: 'var(--color-outer-header-background)', //for the landing page navbar - white color
    text: 'var(--color-outer-header-text)'
  },
  innerHeader: {
    text: 'var(--color-inner-header-text)', //the default text color of the header in the main products
    icon: 'var(--color-inner-header-icon)',
    background: 'var(--color-inner-header-background)',
    btnOpened: 'var(--color-inner-header-btn-opened)',
    popover: 'var(--color-inner-header-popover)',
    btnSelected: 'var(--color-inner-header-btn-selected)',
    btnHover: 'var(--color-inner-header-btn-hover)',
    highlightAccent: 'var(--color-inner-header-highlight-accent)'
  },
  landing: {
    heroBackground: 'var(--color-landing-hero-background)'
  },
  alert: {
    sucess: {
      background: 'var(--color-alert-success-background)',
      border: 'var(--color-alert-success-border)',
      text: 'var(--color-alert-success-text)'
    },
    primary: {
      background: 'var(--color-alert-primary-background)',
      border: 'var(--color-alert-primary-border)',
      text: 'var(--color-alert-primary-text)'
    },
    warning: {
      background: 'var(--color-alert-warning-background)',
      border: 'var(--color-alert-warning-border)',
      text: 'var(--color-alert-warning-text)'
    }
  }
};

export default COLORS;
